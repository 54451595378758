import React from "react";
import { useTranslation } from "react-i18next";

import { IReactiveButtonStatus } from "common/types";

import { ReactiveButton } from "@global/Buttons";
import { Modal } from "@mui/material";

const ConfirmationModal = ({ open, handleClose, onConfirm }) => {
	const { t } = useTranslation();

	return (
		<Modal
			open={open}
			onClose={() => handleClose()}
			className='
				flex
				items-center
				justify-center
				h-full
				w-full
				lg:h-3/4

			'
		>
			<div
				className='
					h-screen
					w-screen
					bg-gradient-to-b
					from-blue-greyish
					to-blue-darkest
					md:h-auto
					md:w-auto
					md:rounded-xl
					md:overflow-hidden
				'
			>
				<div className="flex flex-col mx-auto h-2/3 justify-between items-center">
					<h1 className="
						w-full
						pt-8
						text-center
						font-sans
						uppercase
						font-black
						italic
						text-white
						text-[2rem]
						md:bg-[#101423]
						md:py-4
					"
					>
						{t('profile.modal.title')}
					</h1>
					<p
						className='
						w-3/4
						inline-flex
						items-center
						justify-center
						text-center
						text-gray-light
						font-sans-round
						font-thin
						lg:pt-[5%]
					'
					>
						{t('profile.modal.message')}
					</p>
					<div
						className='
						w-4/5
						flex
						flex-col
						space-y-6
						p-[4%]
						md:space-y-0
						md:flex-row
						md:justify-around
					'
					>
						<ReactiveButton
							status={IReactiveButtonStatus.DEFAULT}
							className='
										p-[2%]
										rounded-lg
										text-white
										font-sans
										uppercase
										italic
										font-black
										md:w-1/5
										'
							onClick={() => { onConfirm(); handleClose() }}
						>
							{t('profile.modal.confirm')}
						</ReactiveButton>
						<ReactiveButton
							status={IReactiveButtonStatus.DEFAULT}
							className='
									p-[2%]
									rounded-lg
									text-white
									font-sans
									uppercase
									italic
									bg-none
									border-2
									border-yellow
									font-black
									md:w-1/5
									'
							onClick={() => handleClose()}
						>
							{t('profile.modal.cancel')}
						</ReactiveButton>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default ConfirmationModal;