import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'common/UserProvider';
import useMetriCool from 'common/useMetriCool';
import { isMobile } from 'react-device-detect';
import jwtDecode from 'jwt-decode';

import { unlinkProvider } from 'common/server';
import {
	HOME_URL,
	METAMAKS_MOBILE_URL,
	PROFILE_ICONS,
	USER_LOCAL_STORAGE_KEY,
} from 'common/const';
import { displayWallet, openUrlAsSelf } from 'common/utils';

import SEO from '@layout/SEO';
import { P } from '@global/Texts';
import { Tabs } from '@global/Tab';
import Header from '@layout/Header';
import TextField from 'pages/profile/TextField';
import LinkField from 'pages/profile/LinkField';
import ConfirmationModal from 'pages/profile/ConfirmationModal';
import CheckmarkField from 'pages/profile/CheckmarkField';

import metaImgPath from 'images/meta-img.png';
import {
	CopyIcon,
} from '@global/iconComponents'

import './styles.scss';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Profile: FC = () => {
	const { t } = useTranslation();
	useMetriCool();
	const [isLoading, setIsLoading] = useState(true);
	const [modalState, setModalState] = useState({ open: false, onConfirm: undefined });
	const [canClose, setCanClose] = useState(false);

	const handleOpen = (modalCallback) => {
		setCanClose(false);
		setModalState({ onConfirm: modalCallback, open: true });
		setTimeout(() => {
			setCanClose(true);
		}, 150);
	}

	const handleClose = () => {
		if (!canClose) return;
		setModalState({ onConfirm: undefined, open: false });
	}

	const [infoList, setInfoList] = useState({
		nickname: {
			value: undefined,
			onClick: undefined
		},
		email: {
			value: undefined,
		},
		wallet: {
			value: '',
			onClick: undefined
		},
		googleAccount: {
			value: undefined,
			onClick: undefined
		},
		appleAccount: {
			value: undefined,
			onClick: undefined
		},
		communication: {
			value: false,
			onClick: undefined
		}

	});

	const { user, address, update, walletManager, setUser } =
		useUserContext();

	const tabsData = useMemo(
		() => [
			{
				label: t('profile.profileTabs.account'),
				content: (!isLoading &&
					<div className="flex flex-col px-4 space-y-4">
						<TextField label={t('profile.nicknameInputTitle')}
							value={infoList.nickname.value}
							placeholder={t('profile.nicknameEmpty')}
							handleEdit={infoList.nickname.onClick}
							editable
						/>
						<TextField
							label={t('profile.emailInputTitle')}
							value={infoList.email.value}
						/>
						{/* <LinkField label={t('profile.walletInputTitle')}
							value={Boolean(infoList.wallet.value)}
							placeholder={t('profile.walletEmpty')}
							onClick={infoList.wallet.onClick}
						/> */}
						<LinkField label={t('profile.googleInputLabel')}
							value={Boolean(infoList.googleAccount.value)}
							placeholder={t('profile.googleInputPlaceholder')}
							onClick={infoList.googleAccount.onClick}
						/>
						<LinkField label={t('profile.appleInputLabel')}
							value={Boolean(infoList.appleAccount.value)}
							placeholder={t('profile.appleInputPlaceholder')}
							onClick={infoList.appleAccount.onClick}
						/>
						<CheckmarkField
							label={t('profile.communicationPreferences')}
							value={infoList.communication.value}
							onClick={infoList.communication.onClick}
							description={t('profile.communicationDescription')}
						/>
					</div>
				),
			},
		],
		[infoList]
	);

	const handleConnect = async () => {
		try {
			if (isMobile) {
				openUrlAsSelf(METAMAKS_MOBILE_URL);
				return;
			}
			if (walletManager.isConfigured()) {
				return await walletManager.connect();
			}
			toast.error(t('profile.noMetamaskError'));
		} catch (error) {
			console.error(error);
		}
	};

	const handleDisconnect = async () => {
		try {
			if (walletManager.isConfigured()) {
				return await walletManager.clear();
			}
			toast.error(t('profile.noMetamaskError'));
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		if (!localStorage.getItem(USER_LOCAL_STORAGE_KEY + '_ID_TOKEN'))
			navigate(HOME_URL);
		if (!user) setIsLoading(true);
		else setIsLoading(false);
	}, [user]);

	useEffect(() => {
		if (user) {
			setInfoList(
				isLoading
					? { ...infoList }
					: {
						nickname: {
							value: user.userNickname,
							onClick: async (newNickname) => {
								try {
									await update({ dbId: user.userId, nickname: newNickname })
									toast.success(t('profile.toaster.changesSuccess'));
								}
								catch {
									toast.error(t('profile.toaster.error'))
								}
							},
						},
						email: {
							value: user.userEmail,
						},
						wallet: {
							value: user.userWallet ? displayWallet(user.userWallet) : '',
							onClick: user.userWallet ? () => handleOpen(async () => {
								try {
									await handleDisconnect();
									const res = await update({ dbId: user.userId, removeWallet: true })
									if (res) { toast.success(t('profile.toaster.accountUnlinkSuccess')) }
								}
								catch (error) {
									toast.success(t('profile.toaster.error'));
									console.error(error)
								}
							}) : async () => {
								let newWallet;
								if (!address) newWallet = await handleConnect();
								if (newWallet) {
									if (
										!(await update({ dbId: user.userId, wallet: newWallet }))
									)
										toast.error(t('profile.walletError2'));
								} else {
									toast.error(t('profile.walletError'));
								}
							}
						},
						googleAccount: {
							value: user.googleId,
							onClick: user.googleId
								? () => handleOpen(async () => {
									const decodedIdToken = jwtDecode(
										localStorage.getItem(
											USER_LOCAL_STORAGE_KEY + '_ID_TOKEN'
										)
									) as any;
									const unlinkResp = await unlinkProvider({
										id: decodedIdToken.sub,
										dbId: user.userId,
										provider: 'google-oauth2',
										access_token: localStorage.getItem(
											USER_LOCAL_STORAGE_KEY + '_ACCESS_TOKEN'
										),
									});
									if (unlinkResp) {
										setUser({
											...user,
											googleId: null,
										});
										toast.success(t('profile.toaster.accountUnlinkSuccess'))
									}
									else
										toast.error(t('profile.toaster.accountUnlinkError'));
								})
								: () =>
									navigate(
										`${process.env.GATSBY_BASE_API_URL
										}/social?provider=google-oauth2&response_type=code&flow=link&access_token=${localStorage.getItem(
											USER_LOCAL_STORAGE_KEY + '_ACCESS_TOKEN'
										)}&id_token=${localStorage.getItem(
											USER_LOCAL_STORAGE_KEY + '_ID_TOKEN'
										)}`
									),
						},
						appleAccount: {
							value: user.appleId,
							onClick: user.appleId
								? () => handleOpen(async () => {
									const decodedIdToken = jwtDecode(
										localStorage.getItem(
											USER_LOCAL_STORAGE_KEY + '_ID_TOKEN'
										)
									) as any;
									const unlinkResp = await unlinkProvider({
										id: decodedIdToken.sub,
										dbId: user.userId,
										provider: 'apple',
										access_token: localStorage.getItem(
											USER_LOCAL_STORAGE_KEY + '_ACCESS_TOKEN'
										),
									});
									if (unlinkResp) {
										setUser({
											...user,
											appleId: null,
										});
										toast.success(t('profile.toaster.accountUnlinkSuccess'))
									}
									else
										toast.error(t('profile.toaster.accountUnlinkError'));
								})
								: () =>
									navigate(
										`${process.env.GATSBY_BASE_API_URL
										}/social?provider=apple&response_type=code&flow=link&access_token=${localStorage.getItem(
											USER_LOCAL_STORAGE_KEY + '_ACCESS_TOKEN'
										)}&id_token=${localStorage.getItem(
											USER_LOCAL_STORAGE_KEY + '_ID_TOKEN'
										)}`
									),
						},
						communication: {
							value: user.mailingList,
							onClick: async () => {
								await update({
									dbId: user.userId,
									mailingList: !user.mailingList,
								});
							},
						}
					}
			);
		}
	}, [user, isLoading, address]);

	return (
		<>
			<SEO
				title={t('page.title')}
				description={t('comingSoon.pageDescription')}
				lang="en"
				imgPath={metaImgPath}
				imgAlt=""
				meta={[]}
			/>
			{!isLoading && user && (
				<main className="profile mx-auto">
					<Toaster />
					<Header />
					<div className="frame1 flex flex-row justify-between py-4 px-4">
						<div className="md:ml-8 flex flex-auto md:flex-none space-x-4 items-center justify-center">
							<img width="54px" src={PROFILE_ICONS[user.userIcon]} />
							<div className="flex flex-col">
								<P className="text-white">{user.userNickname}</P>
								{user.userWallet && (
									<div className="flex space-x-2">
										<P className="text-white">
											{displayWallet(user.userWallet)}
										</P>
										<a
											onClick={() => {
												navigator.clipboard.writeText(user.userWallet);
											}}
											className='cursor-pointer fill-yellow stroke-yellow cursor-pointer hover:fill-white hover:stroke-white'
										>
											{CopyIcon({ width: '24px', height: '24px', fill: 'current', stroke: 'current' })}
										</a>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="mx-auto p-5 md:p-0 md:w-4/5">
						<Tabs tabsData={tabsData} />
					</div>
					<ConfirmationModal open={modalState.open} onConfirm={modalState.onConfirm} handleClose={handleClose} />
				</main>
			)}
		</>
	);
}

export default Profile;