import React, { useEffect, useRef, useState } from 'react';

import './styles.scss';

export function Tabs({ tabsData }) {
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
	const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

	const tabsRef = useRef([]);

	useEffect(() => {
		function setTabPosition() {
			const currentTab = tabsRef.current[activeTabIndex];
			setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
			setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
		}

		setTabPosition();
		window.addEventListener('resize', setTabPosition);

		return () => window.removeEventListener('resize', setTabPosition);
	}, [activeTabIndex]);

	return (
		<div>
			<div className="relative">
				<div className="flex space-x-3 border-tabs">
					{tabsData.map((tab, idx) => {
						return (
							<button
								key={idx}
								ref={(el) => (tabsRef.current[idx] = el)}
								className="pt-2 pb-3 italic"
								onClick={() => setActiveTabIndex(idx)}
								style={{
									color: idx === activeTabIndex ? 'white' : '#40528c',
								}}
							>
								<span className="tab-text">{tab.label}</span>
							</button>
						);
					})}
				</div>
				<span
					className="absolute bottom-0 block h-1 bg-yellow-dark transition-all duration-300"
					style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
				/>
			</div>
			<div className="py-4">{tabsData[activeTabIndex].content}</div>
		</div>
	);
}
